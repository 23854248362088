<template>
    <v-card v-if="isSignedIn">
        <v-btn
            variant="flat"
            color="primary"
            @click="signOut"
        >
            Logout from {{ signedInAs }}
        </v-btn>
    </v-card>

    <v-card v-else>
        <v-card-title>
            <v-btn
                variant="flat"
                color="primary"
                @click="signIn"
            >
                Login as admin
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <label for="username">
                        username
                        <v-text-field
                            id="username"
                            v-model="username"
                        />
                    </label>
                </v-col>
                <v-col>
                    <label for="password">
                        password
                        <v-text-field
                            id="password"
                            v-model="password"
                            type="password"
                        />
                    </label>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
const auth = useAuth();
const username = ref<string>();
const password = ref<string>();

const signIn = async () => {
    if (typeof username.value !== 'string') throw Error('Invalid username');
    if (typeof password.value !== 'string') throw Error('Invalid password');
    await auth.signIn('credentials', { username: username.value, password: password.value });
};

const signOut = async () => {
    await auth.signOut();
};

const isSignedIn = computed(() => auth.status.value === 'authenticated');
const signedInAs = computed(() => auth.data.value?.user?.email ?? 'unavailable');
</script>
